import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.seenByClient = this.element.dataset.seenByClient === 'true'
    this.seenByAgent = this.element.dataset.seenByAgent === 'true'
    this.currentRole = document.body.dataset.currentRole
    this.messageUserRole = this.element.dataset.userRole
    
    if (
      (this.currentRole === 'agent' && this.messageUserRole === "client" && !this.seenByAgent) || 
      (this.currentRole === 'client' && this.messageUserRole === "agent" && !this.seenByClient)
    ) {
      this.markAsSeen()
    }
  }
  
  markAsSeen() {
    fetch(`/messages/${this.element.dataset.id}/mark_as_seen`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      }
    })
    .then(data => {
      if (data.success) {
        if (this.currentRole === 'agent') {
          this.element.dataset.seenByAgent = 'true';
        }
        if (this.currentRole === 'client') {
          this.element.dataset.seenByClient = 'true';
        }
      }
    })
    .catch(error => console.error('Error:', error));
  }
}
