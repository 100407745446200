import "trix"
import "@rails/actiontext"
import "@hotwired/turbo-rails"
import "../controllers"
import "../controllers/new_request_signal"
import "../controllers/agent_service_request"

window.setNavIndicator = function setNavIndicator(activeNavItemSelector, markerSelector, horizontal = true, disableTransition = false) {
  const activeNavItem = document.querySelector(activeNavItemSelector)
  if (activeNavItem === null) return
  const marker = document.querySelector(markerSelector)
  if (marker === undefined || marker?.style === undefined) return

  var originalTransition = null
  if (disableTransition) {
    originalTransition = marker.style.transition
    marker.style.transition = 'none'
  }

  if (horizontal) {
    marker.style.left = activeNavItem.offsetLeft + 'px'
    marker.style.width = activeNavItem.offsetWidth + 'px'
  } else {
    marker.style.top = activeNavItem.offsetTop - 10 + 'px'
    marker.style.height = activeNavItem.offsetHeight + 20 + 'px'
  }

  if (disableTransition) marker.style.transition = originalTransition
}

window.highlightCurrentServiceRequest = function highlightCurrentServiceRequest() {
  const callbackToHighlightCurrentServiceRequest = function(mutationList) {
    for(const mutation of mutationList) {
      if (mutation.type === 'childList') {
        mutation.addedNodes.forEach((e)=> {
          if (e.id) {
            const id = e.id.replace("service_request_", "")
            if (id) {
              const el = document.querySelector(`#agent_sidebar_service_request_${id}`)
              if (el) {
                e.classList.add("selected")
              }
            }
          }
        })
      }
    }
  }

  const targetNode3 = document.getElementById("agent_all_service_requests")
  if (targetNode3) {
    const observer = new MutationObserver(callbackToHighlightCurrentServiceRequest)
    observer.observe(targetNode3, { attributes: false, childList: true, subtree: false })
  }
}

window.setActiveTabIndicator = function setActiveTabIndicator() {
  const targetNode = document.getElementById("agent_sidebar")
  if (targetNode) {
    const callback = function(mutationList) {
      for(const mutation of mutationList) {
        if (mutation.type === 'childList') {
          mutation.removedNodes.forEach((e)=> {
            if (e.id && e.id.match(/agent_sidebar_(user|article)/)) {
              const id = e.id.replace(/agent_sidebar_(user|article)/, "$1")
              if (id) {
                const el = document.querySelector(`#${id}`)
                if (el) {
                  el.classList.remove("selected")
                }
              }
            }
          })

          mutation.addedNodes.forEach((e)=> {
            if (e.id && e.id.match(/agent_sidebar_(user|article)/)) {
              const id = e.id.replace(/agent_sidebar_(user|article)/, "$1")
              if (id) {
                const el = document.querySelector(`#${id}`)
                if (el) {
                  el.classList.add("selected")
                }
              }
            }
          })
        }
      }
    }

    const observer = new MutationObserver(callback)
    observer.observe(targetNode, { attributes: false, childList: true, subtree: false })
  }
}

document.addEventListener('turbo:load', (e) => {
  setNavIndicator("nav .agent-nav-item.active", '#agent-marker', false, false)
  setNavIndicator("nav .client-nav-item.active", '#client-marker', true, false)
  setNavIndicator("nav .agent-call-nav-item.active", '#agent-call-marker', true, false)

  highlightCurrentServiceRequest()
  setActiveTabIndicator()
})

document.addEventListener('turbo:frame-load', (e) => {
  setNavIndicator("nav .agent-call-nav-item.active", '#agent-call-marker', true, false)
})

window.addEventListener('resize', (e) => {
  setNavIndicator("nav .agent-nav-item.active", '#agent-marker', false, true)
  setNavIndicator("nav .client-nav-item.active", '#client-marker', true, true)
  setNavIndicator("nav .agent-call-nav-item.active", '#agent-call-marker', true, true)
})

document.addEventListener('turbo:load', (e) => {
  if (window.location.hash === "#articles-anchor") {
    setTimeout(() => {
      document.getElementById("articles-anchor")?.scrollIntoView()
    }, 250)
  }
})

window.callController = function callController() {
  const callElement = document.querySelector("[id*='call_service_request_']")
  const callController = Stimulus.getControllerForElementAndIdentifier(callElement, "call")
  
  return callController
}

window.closeDropdown = function(element) {
  !element.closest('.dropdown').querySelector('.dropdown-menu').getAnimations().some(a => a.playState === 'running') 
    && element.blur();
}
